// sample_app/app/javascript/packs/menu.js
// クリックすると次の階層のメニューに切り替わる
// document.addEventListener('turbolinks:load', () => {
//   const largeCategories = document.querySelectorAll('.large-category');
//   const middleCategoryContainers = document.querySelectorAll('.middle-category-container');
//   const mainMenuButtons = document.querySelectorAll('.main-menu');
//   const overlay = document.querySelector('.overlay');

//   largeCategories.forEach((largeCategory) => {
//     largeCategory.addEventListener('click', () => {
//       const largeGenreId = largeCategory.dataset.largeGenreId;
//       const targetMiddleCategory = document.getElementById(`middle-category-${largeGenreId}`);

//       middleCategoryContainers.forEach((container) => {
//         container.style.left = '-100%';
//         container.classList.remove('show');
//       });

//       targetMiddleCategory.style.left = '0';
//       targetMiddleCategory.classList.add('show');

//       largeCategories.forEach((largeCategory) => {
//         largeCategory.style.left = '100%'; // 変更
//       });

//       overlay.style.display = 'block';
//     });
//   });

//   mainMenuButtons.forEach((mainMenuButton) => {
//     mainMenuButton.addEventListener('click', () => {
//       middleCategoryContainers.forEach((container) => {
//         container.style.left = '-100%';
//         container.classList.remove('show');
//       });

//       largeCategories.forEach((largeCategory) => {
//         largeCategory.style.left = '0'; // 変更
//       });

//       overlay.style.display = 'none';
//     });
//   });

//   overlay.addEventListener('click', () => {
//     middleCategoryContainers.forEach((container) => {
//       container.style.left = '-100%';
//       container.classList.remove('show');
//     });

//     largeCategories.forEach((largeCategory) => {
//       largeCategory.style.left = '0'; // 変更
//     });

//     overlay.style.display = 'none';
//   });
// });

// ハンバーガーメニュー
// document.addEventListener("turbolinks:load", () => {
//   const hamburgerButton = document.querySelector(".hamburger");
//   const hamburgerMenu = document.getElementById("hamburger-menu");
//   const hamburgerText = document.querySelector(".hamburger-text");
//   const sidebar = document.querySelector(".sidebar");
//   const overlay = document.querySelector(".overlay");

//   hamburgerMenu.addEventListener("click", function() {
//     toggleMenu();
//   });

//   hamburgerText.addEventListener("click", function() {
//     toggleMenu();
//   });

//   function toggleMenu() {
//     sidebar.classList.toggle("show");
//     overlay.style.display = overlay.style.display === "block" ? "none" : "block";
//   }

//   overlay.addEventListener("click", () => {
//     sidebar.classList.remove("show");
//     overlay.style.display = "none";
//   });
// });


